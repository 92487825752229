@mixin fontFace($family, $src, $style: normal, $weight: 400) {
  @font-face {
    font-family: $family;
    src: url("#{$src}.otf") format("truetype");
    font-style: $style;
    font-weight: $weight;
  }
}

@include fontFace("Rubik", "./assets/fonts/Rubik/Rubik-Black", bold, 800);

@include fontFace("Adversal", "./assets/fonts/Adversal/adversal", normal, 600);

@include fontFace("PlayfairDisplay", "./assets/fonts/PlayfairDisplay/PlayfairDisplay-Bold", bold, 700);

@include fontFace(
  "Outfit-Bold",
  "./assets/fonts/Outfit/Outfit-SemiBold",
  normal,
  600
);

@include fontFace(
  "Outfit-Medium",
  "./assets/fonts/Outfit/Outfit-Medium",
  normal,
  500
);

@include fontFace(
  "Outfit-Regular",
  "./assets/fonts/Outfit/Outfit-Regular",
  normal,
  400
);

.font-outfit-bold {
  font-family: "Outfit-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Bold", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
.font-outfit-medium {
  font-family: "Outfit-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Medium", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
.font-outfit-regular {
  font-family: "Outfit-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.font-adversal-title {
  font-family: "Adversal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  margin-top: 8px;
}

.font-adversal {
  font-family: "Adversal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.font-rubik {
  font-family: "Rubik";
}

.font-fair {
  font-family: "PlayfairDisplay";
}

.row_flex_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.column_flex_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text_no_wrap {
  white-space: nowrap;
}

.row_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.row_align_items {
  display: flex;
  align-items: center;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.center {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify_start {
  display: flex;
  justify-content: start;
}

.thumb-vertical {
  z-index: 3;
  border-radius: 15px;
  background-color: #ff3e9a;

  width: 0px !important;
  height: 0px;
}

.thumb-horizontal {
  z-index: 3;
  border-radius: 15px;
  background-color: #ff3e9a;

  width: 0px !important;
  height: 0px;
}

.text_no_wrap {
  white-space: nowrap;
}

.border_less_input {
  border: none;
}
.border_less_input:focus {
  outline: none;
}

.app-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 8px;
}

.image_view_modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  z-index: 1000000;
}

.hyper_text {
  color: blue;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}