.primary_toast {
  max-width: calc(100vw - 32px);
  width: 100%;
  padding: 8px;
  padding-right: 24px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  gap: 12px;
  position: relative;

  .success_toast_icon {
    min-width: 32px;
    height: 32px;
    border-radius: 32px;
    background: rgba(43, 188, 58, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error_toast_icon {
    min-width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: rgba($color: #ff3e9a, $alpha: 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 12px;
  }
}

.Toastify__toast-container {
  z-index: 10000000 !important;
}

.Toastify__toast-theme--light {
  background: transparent !important;
  box-shadow: none !important;
  color: black !important;
  padding: 0px !important;
  // height: 300px !important;
  z-index: 10000000 !important;

  .Toastify__close-button {
    display: none;
  }

  .Toastify__progress-bar--wrp {
    height: 0px !important;
  }

  .Toastify__toast-body > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
